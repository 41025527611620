export default class ComarcaService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }

    buscarUfs() {
        return this._http.post('consultaufs');
    }

    buscaDadosComarcas(uf) {
        return this._http.post('dados_comarcas', {
            uf: uf
        });
    }

    buscarComarcas(filtros) {
        let cd_ibge_comarca = filtros.cd_ibge_comarca || '';
        return this._http.get('comarcas/'+ cd_ibge_comarca, {
            params: {
                cd_uf: filtros.cd_uf,
                nm_municipio: filtros.nm_municipio,
                cep_comarca: filtros.cep_comarca
            }
        });
    }

    editarComarcas(comarca) {
        return this._http.get(`comarcas/`+ comarca.cd_ibge_comarca, {
            params: {
                cd_ibge_comarca: comarca.cd_ibge_comarca,
                nm_municipio: comarca.nm_municipio
            }
        });
    }

    salvarComarcas(comarca) {
        return this._http.put(`comarcas/` + comarca.cd_ibge_comarca, {
            ds_limite_diario: comarca.ds_limite_diario,
            st_ativo: comarca.st_ativo
        });
    }
}
