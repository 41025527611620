import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-3", attrs: { align: "center", justify: "center" } },
    [
      _c(
        VCard,
        { attrs: { align: "left", justify: "left" } },
        [
          _c(
            VCardText,
            { staticClass: "pa-3 font-weight-light white black--text" },
            [
              _c(VIcon, { staticClass: "primary--text lighten-2 pa-2" }, [
                _vm._v(" mdi-map-marker-circle")
              ]),
              _vm._v("Comarcas > Listar Comarcas ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            VCard,
            {
              staticClass: "pa-5 my-3",
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.botaoListarComarcas.apply(null, arguments)
                }
              }
            },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    {
                      staticClass: "alinhar-filtros",
                      class: _vm.isExtraSmall || _vm.isSmall ? "pb-0" : "",
                      attrs: { cols: "12", sm: "12", md: "4", lg: "3" }
                    },
                    [
                      _c(VTextField, {
                        attrs: {
                          solo: "",
                          "prepend-icon":
                            !_vm.isExtraSmall && !_vm.isSmall
                              ? "mdi-chevron-right"
                              : "",
                          small: "",
                          label: "Código da Comarca",
                          hint: "Informe o Código da Comarca que deseja buscar",
                          placeholder: "Ex.:0000349",
                          clearable: ""
                        },
                        model: {
                          value: _vm.filtros.cd_ibge_comarca,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "cd_ibge_comarca", $$v)
                          },
                          expression: "filtros.cd_ibge_comarca"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "alinhar-filtros",
                      class: _vm.isExtraSmall || _vm.isSmall ? "py-0" : "",
                      attrs: { cols: "12", sm: "12", md: "6", lg: "4" }
                    },
                    [
                      _c(VTextField, {
                        attrs: {
                          solo: "",
                          small: "",
                          label: "Nome do Município",
                          hint: "Informe o nome do múnicípio que deseja buscar",
                          placeholder: "Ex.: Belo Horizonte",
                          clearable: ""
                        },
                        model: {
                          value: _vm.filtros.nm_municipio,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "nm_municipio", $$v)
                          },
                          expression: "filtros.nm_municipio"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      class: _vm.isExtraSmall || _vm.isSmall ? "py-0" : "",
                      attrs: { cols: "12", sm: "12", md: "2", lg: "2" }
                    },
                    [
                      _c(VSelect, {
                        attrs: {
                          solo: "",
                          small: "",
                          items: _vm.listaUfs,
                          label: "UF",
                          "return-object": true
                        },
                        model: {
                          value: _vm.filtros.cd_uf,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "cd_uf", $$v)
                          },
                          expression: "filtros.cd_uf"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "alinhar-filtros",
                      class: _vm.isExtraSmall || _vm.isSmall ? "py-0" : "",
                      attrs: { cols: "12", sm: "12", md: "4", lg: "3" }
                    },
                    [
                      _c(VTextField, {
                        directives: [
                          {
                            name: "maska",
                            rawName: "v-maska",
                            value: "#####-###",
                            expression: "'#####-###'"
                          }
                        ],
                        attrs: {
                          solo: "",
                          "prepend-icon": _vm.isMedium
                            ? "mdi-chevron-right"
                            : "",
                          small: "",
                          label: "CEP",
                          hint: "Informe o CEP da Comarca que deseja pesquisar",
                          placeholder: "Ex.: 00000-000",
                          clearable: ""
                        },
                        model: {
                          value: _vm.filtros.cep_comarca,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "cep_comarca", $$v)
                          },
                          expression: "filtros.cep_comarca"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "px-3",
                      class: _vm.isExtraSmall
                        ? "text-center pt-0"
                        : _vm.isSmall
                        ? "text-right pt-0"
                        : "text-right pt-3",
                      attrs: {
                        cols: "12",
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "12"
                      }
                    },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: {
                            color: "green white--text",
                            "data-cy": "buscarComarcasBtn"
                          },
                          on: { click: _vm.botaoListarComarcas }
                        },
                        [
                          _vm._v(" Buscar Comarcas "),
                          _c(VIcon, { staticClass: "ml-2" }, [
                            _vm._v("search")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.snackbar
            ? _c("Snackbar", {
                attrs: {
                  mostrarSnackbar: _vm.snackbar,
                  corSnackbar: _vm.snackbarColor,
                  mensagemSnackbar: _vm.mensagemRetorno
                },
                on: {
                  fecharSnackbar: function($event) {
                    _vm.snackbar = false
                  }
                }
              })
            : _vm._e(),
          _c(VDataTable, {
            staticClass: "elevation-1 pa-3",
            class: _vm.isExtraSmall ? "alinhar-botao-editar" : "",
            attrs: {
              "data-cy": "dataTableComarcas",
              loading: _vm.loading,
              "no-data-text": "Não há comarcas listadas",
              "loading-text": "Carregando Comarcas...",
              headers: _vm.headers,
              items: _vm.listaComarcas,
              "item-key": "comarcas",
              "footer-props": {
                itemsPerPageOptions: [10, 20, 50, -1],
                itemsPerPageText: "Comarcas por página:"
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.st_ativo",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(VSwitch, {
                        style: {
                          color: item.st_ativo != "" ? "green" : "red"
                        },
                        attrs: {
                          color: item.st_ativo != "" ? "green" : "red",
                          inset: ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.alterarStatus(item)
                          }
                        },
                        model: {
                          value: item.st_ativo,
                          callback: function($$v) {
                            _vm.$set(item, "st_ativo", $$v)
                          },
                          expression: "item.st_ativo"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.is_admin",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        VIcon,
                        {
                          style: {
                            color: item.is_admin != "" ? "green" : "red"
                          }
                        },
                        [_vm._v(_vm._s(_vm.getBolean(item.is_admin)))]
                      )
                    ]
                  }
                },
                {
                  key: "item.status",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.status == "true"
                        ? _c("span", [_c("p", [_vm._v("Ativo")])])
                        : _vm._e(),
                      item.cd_status == "false"
                        ? _c("span", [_c("p", [_vm._v("Desabilitado")])])
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function(props) {
                    return [
                      _c(
                        VBtn,
                        {
                          staticClass: "grey white--text",
                          class: !_vm.isExtraSmall ? "mr-2" : "mb-2 mr-3",
                          attrs: {
                            elevation: "0",
                            width: "90%",
                            loading: _vm.dialogLoading,
                            "data-cy": "editarComarca" + props.index
                          },
                          on: {
                            click: function($event) {
                              return _vm.exibirDetalhe(props.item)
                            }
                          }
                        },
                        [
                          _c(
                            VIcon,
                            {
                              staticClass: "mr-2",
                              style: { color: "white" },
                              attrs: { medium: "" },
                              on: {
                                click: function($event) {
                                  return _vm.exibirDetalhe(props.item)
                                }
                              }
                            },
                            [_vm._v("edit")]
                          ),
                          _vm._v("Editar ")
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm.dialogEditar
        ? _c(
            VDialog,
            {
              attrs: {
                "max-width": "1000px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialogEditar,
                callback: function($$v) {
                  _vm.dialogEditar = $$v
                },
                expression: "dialogEditar"
              }
            },
            [
              _c(
                VCard,
                [
                  _c(
                    VCardTitle,
                    { staticClass: "pa-0" },
                    [
                      _c(
                        VToolbar,
                        { attrs: { flat: "", dark: "", color: "primary" } },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeDialogComarca }
                            },
                            [_c(VIcon, [_vm._v("mdi-close")])],
                            1
                          ),
                          _c(VToolbarTitle, [_vm._v("Editar Comarca")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(VDivider),
                  _c(
                    VCardText,
                    { staticClass: "px-8 py-4" },
                    [
                      _c("EditarComarca", {
                        attrs: { comarca: _vm.comarca },
                        on: { fecharModal: _vm.closeDialogComarca }
                      })
                    ],
                    1
                  ),
                  _c(VDivider),
                  _c(
                    VCardActions,
                    {
                      style: !_vm.isExtraSmall
                        ? "display: flex; justify-content: end"
                        : "display: flex; justify-content: center"
                    },
                    [
                      _c(
                        VBtn,
                        {
                          class: !_vm.isExtraSmall ? "ml-5" : "",
                          attrs: {
                            color: "green white--text",
                            "data-cy": "salvarComarca",
                            width: _vm.isExtraSmall ? "100" : "120"
                          },
                          on: { click: _vm.botaoSalvarComarca }
                        },
                        [_vm._v(" Salvar ")]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "ml-5",
                          attrs: {
                            dense: "",
                            color: "error",
                            width: _vm.isExtraSmall ? "100" : "120"
                          },
                          on: { click: _vm.closeDialogComarca }
                        },
                        [_vm._v(" Cancelar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }